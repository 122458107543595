import React, { useEffect, useState, useCallback } from 'react';
import ModalContext from './ModalContext';
import ReactModal from 'react-modal';
import { css } from 'linaria';
const preventBodyScrollStyle = "po6xh9f";

const ModalProvider = ({
  children
}) => {
  const [modals, setModals] = useState([]);

  function showTarget(modalComponent, modalType, modalId, props = {}) {
    setModals(prevModals => {
      const newModals = [...prevModals];
      const modalProps = {
        modalComponent,
        modalId,
        modalType,
        isOpen: true,
        props,
        showTarget,
        hideTarget: () => hideTarget(modalId)
      }; // Check if modal with same id already exists

      const matchIndex = newModals.findIndex(modal => modal.modalId === modalId);
      if (matchIndex >= 0) newModals[matchIndex] = modalProps;else newModals.push(modalProps);
      return newModals;
    });
  }

  function hideTarget(modalId) {
    setModals(prevModals => {
      const newModals = [...prevModals]; // Calling hideTarget directly from a MouseEvent passes down an event
      // object. Therefore we have to check if the passed prop is a string.

      if (modalId && typeof modalId === 'string') {
        // Removing the modal based on modalId if opened
        const matchIndex = newModals.findIndex(modal => modal.modalId === modalId);
        if (matchIndex >= 0) newModals.splice(matchIndex, 1);
      } else {
        // Removing the last opened modal
        newModals.pop();
      }

      return newModals;
    });
  } // Handling modal close on Escape button


  const handleKeydown = useCallback(e => {
    if (e.key !== 'Escape' || modals.length === 0) return;
    const lastModal = modals[modals.length - 1];
    if (!lastModal.props.canCloseOnEsc) return;
    hideTarget();
  }, [modals]);
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]); // Disabling scrolling when relevant

  useEffect(() => {
    const preventOverflow = modals.some(modal => modal.props.preventOverflow || modal.modalType === 'drawer' && modal.isOpen);
    if (preventOverflow) document.body.classList.add(preventBodyScrollStyle);else document.body.classList.remove(preventBodyScrollStyle);
    return () => document.body.classList.remove(preventBodyScrollStyle);
  }, [modals]);
  const initialState = {
    showTarget,
    hideTarget,
    modals
  };
  return React.createElement(ModalContext.Provider, {
    value: initialState
  }, children);
};

export default ModalProvider;

require("../../../../.linaria-cache/node_modules/@jetshop/ui/Modal/ModalProvider.linaria.css");